.contact-info-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #182C35;
    margin-bottom: 1rem;
}

.contact-info-sub-title {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #6C757D;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.contact-info-radio {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}