.nav-item {
    padding: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.nav-item-img {
    margin-right: 1rem;
}

.sidebar-logo {
    height: 1.8rem;
    margin-bottom: 2rem;
    align-self: flex-start;
    cursor: pointer;
}

.sidebar {
    background-color: #ffffff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.item-list {
    align-self: flex-start;
    font-size: 20px;
    line-height: 24px;
    color: #1B2149;
    margin-top: 5rem;
    text-align: left;
    cursor: pointer;
}

.active {
    background: #F7F7FF;
    border-radius: 5px;
    border-left: 5px solid #1551C4;
}


.order-root {
    display: flex;
}

.order-main-screen {
    display: flex;
    align-items: center;
    width: 100vw;
    background: #E5E5E5;
    padding: 0 2rem;
    min-height: 100vh;
    flex-direction: column;
}

.order-main-screen .heading {
    display: flex;
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    margin-top: 1.5rem;
    width: 100%;
}

.order-main-screen .heading .user {
    display: flex;
    align-items: center;
    align-self: center;
}

.order-main-screen .heading .user .pic {
    background: rgba(21, 81, 196, 0.1);
    border-radius: 50%;
    color: #1551C4;
    height: 50px;
    width: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}


.order-main-screen .heading .user div {
    padding: 0 0.5rem;
    cursor: pointer;
}

.order-main-screen .heading .title-search {
    display: flex;
    align-items: center;
    align-self: flex-start;
}

.order-main-screen .search-box {
    height: 3rem;
    background: rgba(21, 81, 196, 0.1);
    border-radius: 5px;
    border: none;
    align-self: center;
    width: 6rem;
    text-align: center;
    margin-left: 2rem;

}

.order-main-screen .heading .title {
    font-size: 30px;
    line-height: 36px;
    color: #1B2149;
    text-align: center;
}

.order-main-screen .body {
    width: 100%;
    margin-top: 2rem;
}

.order-main-screen .body .first-sec div select {
    border: none;
    background: #E5E5E5;
    color: #1551C4;
}

.order-main-screen .body .first-sec div select:focus-visible {
    outline: none;
}