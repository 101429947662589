.form-card-container {
    /* display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column; */
    background: #FFFFFF;
    box-shadow: 0px 20px 35px rgba(66, 85, 99, 0.11);
    border-radius: 12px;
    width: 60vw;
    margin-top: 7%;
    padding: 50px 50px;
    /* height: 500px; */
    /* overflow-y: scroll; */
}

.form-card-status {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
}

/* .form-card-container::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.form-card-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D62929;
} */