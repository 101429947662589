.product-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #F4F5F8;
    box-shadow: 0px 8px 15px rgba(66, 85, 99, 0.08);
    border-radius: 12px;
    box-sizing: border-box;
    height: 26rem;
    width: 18rem;
    margin: auto;
    align-self: center;
}

.product-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 1rem 0;
}

.product-inner {
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-inner img {
    position: relative;
    z-index: 999;
}

.product-card-image {
    padding: 2rem;
    background: linear-gradient(90deg, #FC1255 0%, #D41D24 50%, #AB2328 100%);
    border-radius: 12px;
    transition: .9s;
    position: absolute;
    top: 0;
    width: 150px;
    height: 150px;
    left: 0;
}

.product-card:hover {
    box-shadow: 0px 8px 15px rgba(66, 85, 99, 0.15), 0px 8px 15px rgba(66, 85, 99, 0.15);
}

.product-card:hover .product-card-image {
    transform: rotate(45deg);
    transition: .3s ease;
}


.product-card:hover .product-card-image img {
    transform: rotate(-45deg);
    transition: .3s ease;
}

.product-card-title {
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 24px;

    text-align: center;

    color: #182C35;
}

.product-card-content {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #425563;
    padding: 0 1rem;
    animation: 0.3s ease;
}

.product-info-container {
    align-self: flex-end;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F5F8;
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    height: 0%;
    transition: height 0.3s;
}

.product-info {
    display: flex;
    align-items: center;
    text-align: center;
    color: #D41D24;
    opacity: 0%;
    transition: opacity 0.2s;
    cursor: pointer;
    font-weight: 600;
}

.product-card:hover .product-info-container {
    opacity: 100%;
    height: 10%;
    transition: height 0.3s;
}

.product-card:hover .product-info {
    opacity: 100%;
    transition: opacity 0.3s;
}