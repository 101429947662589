/* @font-face {
    font-family: 'GalaxiePolarisMedium';
    src: url('../../assets/fonts/GalaxiePolarisMedium.otf') format('opentype');
} */

.header {
    font-family: 'GalaxiePolarisMedium';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo {
    margin-left: 1rem;
    align-items: center;
    cursor: pointer;
}

.nav-links {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-product {
    padding: 1rem;
    align-self: center;
    cursor: pointer;
}

.nav-login {
    padding: 1rem;
    align-self: center;
}

.nav-login button {
    height: 3rem;
    width: 10rem;
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
}