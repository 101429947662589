.status-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
}

.status-container-text {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #182C35;
}

.status-container-number-current {
    background: #B72127;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    text-align: center;
    align-self: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-container-number-completed {
    background: #B72127;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    text-align: center;
    align-self: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-container-number-not-done {
    background: #F4F5F8;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    text-align: center;
    align-self: center;
    color: #182C35;
    display: flex;
    justify-content: center;
    align-items: center;
}