.contact-us-root {
    width: 100%;
    background: linear-gradient(180deg, #F4F5F8 0%, #FFFFFF 100%);
    margin-top: 3rem;
    padding-top: 3rem;
}

.contact-us {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin: 2rem auto;
}

.product {
    display: flex;
    flex-direction: column;
}

.product-head {
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #425563;
}

.product-name {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #425563;
}

.call-text {
    font-weight: 450;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: #182C35;
    width: 40vw;
}

.call-number {
    display: flex;
    justify-content: space-evenly;
    width: 40vw;
}

.call-number button {
    font-weight: 550;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
    padding: 1rem 3rem;
}