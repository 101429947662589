.login-card-container {
    background: #FFFFFF;
    box-shadow: 0px 20px 35px rgba(66, 85, 99, 0.11);
    border-radius: 12px;
    width: 35vw;
    margin-top: 20%;
    padding: 50px 50px;
}

.reg-title {
    font-style: normal;
    font-weight: 550;
    font-size: 24px;
    line-height: 24px;
    color: #182C35;
    margin-bottom: 4rem;
}

.Error-Message{
    margin-bottom:30px ;
    color: #D41D24;
}

.input-fields input {
    background: #FFFFFF;
    border: 1px solid rgba(25, 44, 53, 0.5);
    border-radius: 6px;
}

.input-fields .form-control {
    position: relative;
    width: 100%;
    height: 3.2rem;
    margin: 0 auto;
    border: none;
    margin-bottom: 2rem;
}

.input-fields .form-label {
    position: absolute;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    left: 0.75rem;
    top: 0.75rem;
    padding: 0 0.35rem;
    color: #6C757D;
    background: var(--color-white);
    transition: all 0.35s ease;
}

.input-fields .form-input {
    position: absolute;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0.75rem 1rem;
    z-index: 1;
    outline: none;
    border-radius: 0.25rem;
    border-width: 1.75px;
    border-style: solid;
    border-color: rgba(25, 44, 53, 0.5);
    color: #182C35;
    background-clip: padding-box;
    background-color: transparent;
    transition: all 0.35s ease-in-out;
}

.input-fields .form-input::placeholder {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.input-fields .form-input:focus+.form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.input-fields .form-input:not(:placeholder-shown).form-input:not(:focus)+.form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.form-label-forgotpassword{
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #D41D24;
    cursor: pointer;
    margin-left: 262px;
    margin-top: -20px;
    margin-bottom: 10px;
}

.login-form button {
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
    width: 100%;
    height: 3rem;
}

.login-form button:focus-visible {
    box-shadow: none;
}

.login-text {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #425563;
}

.login-link {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #D41D24;
    cursor: pointer;
}

.login-content {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.login-content div {
    margin: 1rem 0
}

.login-content-highlight {
    color: #D41D24;
    font-size: 2rem;
}

.inputIcon {
    position: absolute;
    right: 10px;
    top: 11px;
    cursor: pointer;
    z-index: 99;
}