.first-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: linear-gradient(0deg, #F4F5F8 0%, #FFFFFF 50%);
    width: 100%;
    margin-top: 2rem;
}

.catch-line div {
    font-style: normal;
    font-weight: 450;
    font-size: 47px;
    line-height: 55px;
    margin-bottom: 1rem;
}

.catch-line button {
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
    padding: 12px 26px;
    gap: 10px;
}