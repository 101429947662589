.sku-select-heading {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #6C757D;
}

.select-sku-heading div::after {
    width: 100%;
    height: 3px;
    border: 2px solid black;
}

.line {
    border: 1px solid rgba(214, 214, 214, 0.6);
    margin: 0.75rem 0;
    /* background: red; */
}

.back-btn {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #425563;
    padding-right: 1rem;
    align-self: center;
    cursor: pointer;
}