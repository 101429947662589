.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    width: 100vw;
    height: 4rem;
    margin-top: 5rem;
}

.footer-logo {
    align-self: center;
}

.footer-links {
    align-self: center;
}

.footer-links span {
    position: relative;
    padding: 0 0.75rem;
    text-decoration: underline;
    color: #6C7A86;
    cursor: pointer;
}

.footer-links span:first-child {
    text-decoration: none;
}

.footer-links span::after {
    content: "";
    position: absolute;
    border: 1px solid #D3D3D3;
    height: 1.75rem;
    margin-left: 0.75rem;
}

.footer-links span:last-child::after {
    border: none;
}

.footer-social {
    align-self: center;
    border: 1px solid #D3D3D3;
    border-radius: 3px;
    padding: 0.75rem
}

.footer-social span {
    position: relative;
    padding: 0 0.75rem;
    text-decoration: underline;
    color: #6C7A86;
    cursor: pointer;
}

.footer-social span::after {
    content: "";
    position: absolute;
    border: 1px solid #D3D3D3;
    height: 1.75rem;
    margin-left: 0.75rem;
}

.footer-social span:last-child::after {
    border: none;
}