.next-step-btn {
    width: 365px;
    height: 48px;
    font-weight: 550;
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
    font-size: 18px;
}

.trial-container-title {
    height: 2rem;
}