.registration {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.rpg1 {
    align-self: end;
    display: flex;
}

.rpg2 {
    align-self: flex-end;
    margin-bottom: 15rem;
}

.reg-card {
    position: absolute;
}