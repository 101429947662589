.contact-info-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #182C35;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.contact-info-sub-title {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #6C757D;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.contact-info-radio {
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-info-section {
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    color: #6C757D;
    margin-bottom: 0.75rem;
    display: flex;
}

.sign-doc-modal {
    position: absolute;
}

.sign-doc-input-btn {
    display: none;
}

.sign-doc-input-btn::-webkit-file-upload-button {
    visibility: hidden;
}

.sign-doc-file-name {
    top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
}

.sign-doc-modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #000000;
    border-radius: 12px;
    padding: 2rem;
    padding-bottom: 8rem;
    margin: 2rem;
    position: relative;
}

.file-icon {
    margin-top: 1rem;
}

.pass-btn {
    width: 100%;
    padding: 0.75rem 0;
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    border: none;
}

.change-file-btn {
    cursor: pointer;
}

.success-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.success-msg div {
    margin-top: 1rem;
}

.address {
    margin-bottom: 0px;
    line-height: 20px;
}