.product-section {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    margin-top: 4rem;
}

.product-section-heading {
    display: flex;
    flex-direction: row;
}

.product-section-heading div:first-child {
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #425563;
}

.product-section-heading div:last-child {
    font-style: normal;
    font-weight: 450;
    font-size: 40px;
    line-height: 48px;
    display: flex;
    align-items: center;
    color: #182C35;
}

.product-list {
    margin-top: 3rem !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: center;
    margin: auto;
}