.companies {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.company-tagline {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    align-self: center;
    color: #182C35;
    margin: auto;
    width: 60vw;
}

.company-list {
    display: flex;
    margin-top: 2rem;
}

.company {
    display: flex;
    justify-content: center;
}