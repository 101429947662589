.section-container {
    padding: 1.5rem
}

.quote-conf-container {
    background: #f5f5f5;
    padding: 2rem 0;
    border-radius: 10px;
}

.quote-confirm-title {
    font-weight: 450;
    font-size: 14px;
    line-height: 15px;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    color: #182C35;
}

.order-edit-btn {
    cursor: pointer;
    padding-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
}

.quote-confirm-name {
    color: #6C757D;
    padding: 0.5rem 0;
}

.quote-confirm-address {
    font-size: 14px;
    line-height: 22px;
    color: #6C757D;
}

.quote-confirm-email {
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #6C757D;
    padding: 0.5rem 0;
}

.quote-details {
    text-align: right;
    color: #6C757D;
}

.quote-details .quote-details-value {
    text-align: left;
    color: #182C35;
}

.representative-key {
    text-align: left;
    color: #6C757D;
    /* padding: 0.2rem 0 */
    margin: 0.2rem 0;
}

.quote-details-value {
    color: #182C35;
}

.representative-heading {
    display: flex;
    align-items: center;
    color: #182C35;
    margin: .2rem 0;
}

.row-style {
    padding: 0.2rem 0
}

.product-price {
    margin-top: 1rem;
}

.tax-info-text {
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    color: #6C757D;
}

.quote-conf-container input {
    margin: 0.1rem 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 0.5rem;
    width: 100%;
}

.quote-conf-container select {
    margin: 0.1rem 0;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 0.5rem;
    width: 100%;
}