.product-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.product-form-rpg1 {
    align-self: end;
    display: flex;
}

.product-form-rpg2 {
    align-self: flex-end;
    margin-bottom: 15rem;
}

.product-form-reg-card {
    margin-top: -15rem;
}

.product-form-title {
    font-weight: 500;
    font-size: 50px;
    line-height: 24px;
    text-align: center;
    margin-top: 3rem;
    color: #FFFFFF;
}