.sku-select-row-radio {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sku-select-row-radio input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 1px solid #6C757D;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.sku-select-row-radio input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #B72127;
}

.sku-select-row-radio input[type="radio"]:checked::before {
    transform: scale(1);
}

.sku-select-row-service {
    font-weight: 450;
    font-size: 13px;
    line-height: 24px;
    color: #182C35;
}

.sku-select-row-description {
    font-weight: 450;
    font-size: 13px;
    line-height: 24px;
    color: #6C757D;
}

.sku-select-row-meter {
    font-weight: 450;
    font-size: 13px;
    line-height: 24px;
    color: #6C757D;
}

.sku-select-row-container {
    border-radius: 12px;
    padding: 3rem;
    transition: background 100ms ease-in-out;
}


.sku-select-row-extra {
    margin-left: 5rem;
    transition: height 100ms ease-in-out;
}

.sku-select-row-option {
    font-weight: 450;
    font-size: 13px;
    line-height: 24px;
    /* identical to box height, or 185% */

    padding: 1rem;
    color: #182C35;
}

.sku-select-row-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #182C35;
}

.sku-select-row-extras-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #182C35;
}