.bootstrap-table {
    width: 100%;
    padding: 5rem;
    margin: 2rem 0;
    background: #ffffff;
}

.bootstrap-table .bootstrap-table-head {
    border-bottom: 1px solid #E2ECFF;
    background: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #767B9E;
}

.bootstrap-table .bootstrap-table-data {
    border-bottom: 1px solid #E2ECFF;
    background: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
    color: #767B9E;
}

.order-id {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1551C4;
    margin: 1rem 0;
    text-align: left;
}

.customer-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1B2149;
    /* text-align: center; */
}

.product {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1551C4;
    /* text-align: center; */
}

.form {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1551C4;
}

.status-submitted {
    background: rgba(95, 181, 0, 0.1);
    border-radius: 71px;
    width: fit-content;
    padding: 0 0.4rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #5FB500;
}

.status-customization {
    background: rgba(250, 171, 52, 0.1);
    border-radius: 71px;
    width: fit-content;
    padding: 0 0.4rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FAAB34;
}

.status-provisioning {
    background: rgba(21, 81, 196, 0.1);
    border-radius: 71px;
    width: fit-content;
    padding: 0 0.4rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #1551C4;
    ;
}

.status-column {
    text-align: end;
}

.status-cell {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
}

.order-id-column {
    text-align: left;
}

.bootstrap-table-root .first-sec {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    align-self: center;
}

.bootstrap-table-root .second-sec {
    display: flex;
    padding: 0 2rem;
    align-self: center;
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 1rem;
    flex-direction: column;
}

.bootstrap-table-root .second-sec .pagination {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-self: center;
    margin-bottom: 1.5rem;
}

.bootstrap-table-root .second-sec .pagination .paginationBtn {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #767B9E;
    border: none;
    background: inherit;
}

.bootstrap-table-root .second-sec .pagination .currPage {
    background: rgba(21, 81, 196, 0.1);
    border-radius: 5px;
    padding: 1rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;

    /* blue */

    color: #1551C4;
}

.common-header {
    /* text-align: center; */
}