.input-component-form-control {
    position: relative;
    width: 100%;
    height: 3.2rem;
    margin: 0 auto;
    border: none;
    margin-bottom: 2rem;
}

.input-component-form-input {
    position: absolute;
    display: block;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: inherit;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0.75rem 1rem;
    z-index: 1;
    outline: none;
    border-radius: 0.25rem;
    border-width: 1.75px;
    border-style: solid;
    border-color: rgba(25, 44, 53, 0.5);
    color: #182C35;
    background-clip: padding-box;
    background-color: transparent;
    transition: all 0.35s ease-in-out;
}

.input-component-form-input::placeholder {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.input-component-form-input:focus+.input-component-form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.input-component-form-input:not(:placeholder-shown).input-component-form-input:not(:focus)+.input-component-form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.input-component-form-input:focus+.input-component-form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.input-component-form-input:not(:placeholder-shown).input-component-form-input:not(:focus)+.input-component-form-label {
    font-size: 0.875rem;
    font-weight: 400;
    top: -0.65rem;
    left: 0.75rem;
    z-index: 999;
    background-color: #fff;
}

.input-component-form-label {
    position: absolute;
    font-family: inherit;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    left: 0.75rem;
    top: 0.75rem;
    padding: 0 0.35rem;
    color: #6C757D;
    background: var(--color-white);
    transition: all 0.35s ease;
}