.product-root {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.section-one {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.section-one-sub-one {
    padding-left: 10rem;
}

.section-one-sub-one p:first-child {
    font-weight: 550;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.9px;
    text-transform: uppercase;
    color: #687782;
}

.section-one-sub-one #tagline {
    font-style: normal;
    font-weight: 450;
    font-size: 47px;
    line-height: 55px;
    /* or 117% */
    display: flex;
    align-items: center;
    color: #182C35;
}

.section-one-sub-one-btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.section-one-sub-one-btn button:first-child {
    background: linear-gradient(140deg, #D41D24 0%, #AB2328 100%);
    border-radius: 6px;
    color: #FFFFFF;
    border: none;
    height: 3rem;
    padding: 12px 26px;
}

.section-one-sub-one-btn button:last-child {
    background: #fff;
    border-radius: 6px;
    color: #D41D24;
    border: 2px solid #AB2328;
    height: 3rem;
    padding: 12px 26px;
    display: flex;
    align-items: center;
    font-weight: 550;
    color: #B72127;
    margin-left: 3rem;
}

.section-two {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.section-three {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.section-two-sub-one {
    position: relative;
    display: flex;
    justify-content: center;
    /* background-image: url('../../assets/images/product-page-graphics-2.svg'); */
}

.section-two-sub-one-content {
    position: absolute;
}

.section-two-sub-one-content p {
    position: relative;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    padding-top: 4rem;
}

.section-two-sub-one-content p::after {
    position: absolute;
    content: " ";
    background: #D41D24;
    height: 0.188rem;
    width: 3.875rem;
    bottom: -20px;
    left: 0;
}

.section-two-sub-two {
    width: 45rem;
}